/* eslint-disable react/jsx-pascal-case */
import { useEffect } from 'react';
import '../index.css';
import Hero from '../components/rishi/Hero.tsx';
import Vdo from '../components/rishi/Video-Section.tsx';
import Bottom_Fixed from '../components/rishi/Bottom-Fixed.tsx';
import Things from '../components/keshav/Loop.js';
import Footer from '../components/deepti/Footer.jsx';
import ParallaxPage from '../components/Saksham/ParallaxPage.js';
import Event from './Events.js';

function Main() {
  
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#footer') {
      const footerElement = document.getElementById('footer');
      if (footerElement) {
        footerElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
        <>
            <div className="relative">
            <Hero />
            <Vdo />
            <div className="w-full min-h-screen bg-white">
              <ParallaxPage />
            </div>
          </div>
          <div className="bg-white pt-16">
            <Event cardsToShow={4} />
            <Things />
          </div>
          <Bottom_Fixed />
          <div id="footer"><Footer /> </div>
        </>
  );
}

export default Main;