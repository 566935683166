import React, { useRef, useEffect } from 'react';

const Vdo = () => {
  const horizontalVdoRef = useRef<HTMLVideoElement>(null);
  const verticalVdoRef = useRef<HTMLVideoElement>(null);

  const horizontalVideoSources = [
    '/video/vdoh/vdoh1.mp4',
    '/video/vdoh/vdoh2.mp4',
    '/video/vdoh/vdoh3.mp4',
    '/video/vdoh/vdoh4.mp4'
  ];

  const verticalVideoSources = [
    '/video/vdov/vdov1.mp4',
    '/video/vdov/vdov2.mp4',
    '/video/vdov/vdov3.mp4',
    '/video/vdov/vdov4.mp4'
  ];

  let currentHorizontalVideoIndex = 0;
  let currentVerticalVideoIndex = 0;

  const handleHorizontalVideoEnd = () => {
    if (horizontalVdoRef.current) {
      currentHorizontalVideoIndex = (currentHorizontalVideoIndex + 1) % horizontalVideoSources.length;
      horizontalVdoRef.current.src = horizontalVideoSources[currentHorizontalVideoIndex];
      horizontalVdoRef.current.play();
    }
  };

  const handleVerticalVideoEnd = () => {
    if (verticalVdoRef.current) {
      currentVerticalVideoIndex = (currentVerticalVideoIndex + 1) % verticalVideoSources.length;
      verticalVdoRef.current.src = verticalVideoSources[currentVerticalVideoIndex];
      verticalVdoRef.current.play();
    }
  };

  useEffect(() => {
    const horizontalVideoElement = horizontalVdoRef.current;
    const verticalVideoElement = verticalVdoRef.current;

    if (horizontalVideoElement) {
      horizontalVideoElement.addEventListener('ended', handleHorizontalVideoEnd);
    }

    if (verticalVideoElement) {
      verticalVideoElement.addEventListener('ended', handleVerticalVideoEnd);
    }

    return () => {
      if (horizontalVideoElement) {
        horizontalVideoElement.removeEventListener('ended', handleHorizontalVideoEnd);
      }
      if (verticalVideoElement) {
        verticalVideoElement.removeEventListener('ended', handleVerticalVideoEnd);
      }
    };
  }, []);

  return (
    <>
      <div className='w-full relative -z-20 h-[80vh] block'>
        <div className='w-full h-full top-0 left-0 right-0 bottom-0 object-cover fixed overflow-hidden'>
          <div className='relative overflow-hidden w-full h-full block'>
            {/* Horizontal video for larger screens */}
            <video
              ref={horizontalVdoRef}
              className='vdo hidden md:block'
              autoPlay
              muted
              loop={false} 
              controls={false}
              src={horizontalVideoSources[0]}
            ></video>

            {/* Vertical video for smaller screens */}
            <video
              ref={verticalVdoRef}
              className='vdo object-cover block md:hidden'
              autoPlay
              muted
              loop={false} 
              controls={false}
              src={verticalVideoSources[0]}
            ></video>
          </div>
        </div>
      </div>
    </>
  );
};

export default Vdo;
