import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { useStateContext } from '../../context/index.tsx';
import { useNavigate } from 'react-router-dom'

const Navbar = () => {
  const line1 = useRef<HTMLDivElement>(null);
  const line2 = useRef<HTMLDivElement>(null);
  const nav = useRef<HTMLDivElement>(null);
  const prevScrollY = useRef(0);

  const { menuOpen, setMenuOpen } = useStateContext();

  // Function to handle menu toggle with animations
  const handleMenuToggle = () => {
    if (menuOpen) {
      gsap.to(line1.current, { rotate: 45, y: 3, backgroundColor: 'black', duration: 0.2 });
      gsap.to(line2.current, { rotate: -45, y: -3, backgroundColor: 'black', duration: 0.2 });
    } else {
      gsap.to(line1.current, { rotate: 0, y: 0, backgroundColor: 'black', duration: 0.2 });
      gsap.to(line2.current, { rotate: 0, y: 0, backgroundColor: 'black', duration: 0.2 });
    }
  };

  // Call handleMenuToggle when menuOpen changes
  useEffect(() => {
    handleMenuToggle();  // Trigger the animation when menuOpen changes
  }, [menuOpen]); // menuOpen as the dependency

  // Scroll hide/show logic
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > prevScrollY.current) {
        gsap.to(nav.current, { y: -100, duration: 0.5 });
      } else {
        gsap.to(nav.current, { y: 0, duration: 0.5 });
      }
      prevScrollY.current = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navigation = useNavigate()

  const handleclick = () => {
    navigation('/')
  }

  return (
    <>
      <div ref={nav} className="fixed top-0 left-0 w-full z-[1000]">
        <div className={`w-[100vw] h-[8vh] ${menuOpen ? "bg-yellowbg" : "bg-white"} z-50 fixed flex items-center justify-between`}>
          <div onClick={handleclick} className='rounded-full ml-8 cursor-pointer'>
            <img className="w-16 h-16 rounded-full" src="/eDCLogo.svg" alt="logo" />
          </div>
          <div className="gap-2 hidden md:flex items-center justify-center font-semibold">
            <div className="text-xs hero-text">IDEATE</div>
            <div className="w-4 h-[2px] bg-black"></div>
            <div className="text-xs hero-text">INNOVATE</div>
            <div className="w-4 h-[2px] bg-black"></div>
            <div className="text-xs hero-text">INCUBATE</div>
          </div>
          <div className="flex items-center justify-center gap-2 mr-12">
            <div onClick={() => setMenuOpen(!menuOpen)} className="flex items-center justify-center gap-2 cursor-pointer">
              <div className="text-sm font-medium hero-text">MENU</div>
              <div className="font-bold flex flex-col gap-1 cursor-pointer">
                <div ref={line1} className="w-[0.8rem] h-[0.15rem] bg-black transition-all"></div>
                <div ref={line2} className="w-[0.8rem] h-[0.15rem] bg-black transition-all"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;