import React, { useState } from 'react';
import './TeamPage.css'; // Ensure to import the CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'; // LinkedIn is from brands
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';  // Envelope is from solid icons

const TeamPage = () => {
  const [hoveredMemberIndex, setHoveredMemberIndex] = useState(null);

  const teamMembers = [
    {
      name: 'Om Patil',
      role: 'Overall Coordinator',
      imageSrc: 'teamimage/om.png',
      linkedin: 'https://www.linkedin.com/in/om-patil-1606b1231?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
      email: 'mailto:ompatil2524@gmail.com',
      color: '#ccf3ff',
    },
    {
      name: 'Tanishq Gupta',
      role: 'Co-Overall Coordinator',
      imageSrc: 'teamimage/tanishq.png',
      linkedin: 'https://www.linkedin.com/in/tanishq21/',
      email: 'mailto:tanishq1103@gmail.com',
      color: '#ebe0ff',
    },
    {
      name: 'Vinil Verma',
      role: 'Co-Overall Coordinator',
      imageSrc: 'teamimage/vinil.png',
      linkedin: 'https://www.linkedin.com/in/vinil-verma-540604231?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',
      email: 'mailto:vinilverma27@gmail.com',
      color: '#FFF2D4',
    },
    {
      name: 'Pratyaksh',
      role: 'Technical Coordinator',
      imageSrc: 'teamimage/pratyaksh.png',
      linkedin: 'https://www.linkedin.com/in/pratyaksh-dhairya-panwar/',
      email: 'mailto:pratyaksh@edciitd.com',
    },
    {
      name: 'Archit Tayal',
      role: 'Startup Support Coordinator',
      imageSrc: 'teamimage/archit.png',
      linkedin: 'https://www.linkedin.com/in/archit-tayal-950980256',
      email: 'mailto:archittayal485@gmail.com',
      color: '#FFF2D4',
    },
    {
      name: 'Sanidhya Garg',
      role: 'Startup Support Coordinator',
      imageSrc: 'teamimage/sanidhya.png',
      linkedin: 'https://www.linkedin.com/in/sanidhya-garg-iitd/',
      email: 'mailto:gsanidhya4@gmail.com',
      color: '#ccf3ff',
    },
    {
      name: 'Akshat Raj',
      role: 'Strategic Partnerships Coordinator',
      imageSrc: 'teamimage/akshat.png',
      linkedin: 'https://www.linkedin.com/in/akshat-raj-',
      email: 'mailto:akshatraj.iitd@gmail.com',
      
    },
    {
      name: 'Kunal',
      role: 'Strategic Partnerships Coordinator',
      imageSrc: 'teamimage/kunal.png',
      linkedin: 'https://www.linkedin.com/in/kunal-aggarwal-/',
      email: 'mailto:Kunal0604@gmail.com',
      color: '#ebe0ff',
    },
    {
      name: 'Ananya',
      role: 'Publicity Coordinator',
      imageSrc: 'teamimage/ananya.png',
      linkedin: 'https://www.linkedin.com/in/ananya-k-07631a267/',
      email: 'mailto:ananyaspta@gmail.com',
    },
    {
      name: 'Vipinsh Mehra',
      role: 'Publicity Coordinator',
      imageSrc: 'teamimage/vipinsh.png',
      linkedin: 'https://www.linkedin.com/in/vipinsh-mehra-871398260/',
      email: 'mailto:vipinshmehra@gmail.com',
      color: '#ebe0ff',
    },
    {
      name: 'Ekansh',
      role: 'Corporate Relations Coordinator',
      imageSrc: 'teamimage/ekansh.png',
      linkedin: 'https://www.linkedin.com/in/ekansh-agarwal-689301250/',
      email: 'mailto:agarwalekansh571@gmail.com',
      color: '#ccf3ff',
    },
    {
      name: 'Kritika',
      role: 'Corporate Relations Coordinator',
      imageSrc: 'teamimage/kritika.png',
      linkedin: 'https://www.linkedin.com/in/kritika-chaudhary-788b69251?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
      email: 'mailto:kritikachaudhary03@gmail.com',
      color: '#FFF2D4',
    },
    {
      name: 'Krushi',
      role: 'Administration & Finance Coordinator',
      imageSrc: 'teamimage/krushi.png',
      linkedin: 'https://www.linkedin.com/in/krushi-thakkar-461515250?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
      email: 'mailto:krushithakkar1607@gmail.com',
      color: '#ccf3ff',
    },
    {
      name: 'Raj Shivhare',
      role: 'Content Coordinator',
      imageSrc: 'teamimage/raj.png',
      linkedin: 'https://www.linkedin.com/me?trk=p_mwlite_feed-secondary_nav',
      email: 'mailto:Shivhare.iitd@gmail.com',
      color: '#FFF2D4',
    },
    {
      name: 'Anushka',
      role: 'Graphic Design Coordinator',
      imageSrc: 'teamimage/anushka.png',
      linkedin: 'https://www.linkedin.com/in/anushka-chowdhary-2b749325b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',
      email: 'mailto:anushkapchowdhary@gmail.com',
      color: '#ebe0ff',
    },
    {
      name: 'Sarthak',
      role: 'Graphic Design Coordinator',
      imageSrc: 'teamimage/Sarthak_creative.png',
      linkedin: 'https://www.linkedin.com/in/sarthak-satsangi-60b2a425a/',
      email: 'mailto:sarthaksatsangi2005@gmail.com',
    },
    {
      name: 'Tanmay',
      role: 'Events Operations Coordinator',
      imageSrc: 'teamimage/tanmay.png',
      linkedin: 'https://www.linkedin.com/in/tanmay-sharma-966237253',
      email: 'mailto:tanmay090703sharma@gmail.com',
      color: '#ccf3ff',
    },
    {
      name: 'Chinmay Yadav',
      role: 'Editorial Coordinator',
      imageSrc: 'teamimage/chinmay.png',
      linkedin: 'https://www.linkedin.com/in/chinmay-yadav-9b7819240?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',
      email: 'mailto:chinmay7080@gmail.com',
      color: '#ebe0ff',
    },
    {
      name: 'Pawar Nandha',
      role: 'Media Coordinator',
      imageSrc: 'teamimage/nandu.png',
      linkedin: 'https://www.linkedin.com/in/nandhakishore-naik-pawar?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',
      email: 'mailto:pawarnandhakishorenaik@gmail.com',
      color: '#FFF2D4',
    },
    // Add more team members here if needed
  ];

  return (
    <div className="page-container mt-[8vh]">
<h1 className='font-Babus mb-4'
                style={{ textAlign: "center", fontSize: "44px", fontWeight: "bold",letterSpacing:"4px" }}
            >Our Team</h1>      <div className="remaining-rows">
        {teamMembers.map((member, index) => (
          <div
          key={index}
          className={`team-member mb-8 ${
            hoveredMemberIndex !== null && hoveredMemberIndex !== index ? 'grayscale' : ''
          }`}
          onMouseEnter={() => setHoveredMemberIndex(index)}
          onMouseLeave={() => setHoveredMemberIndex(null)}
          style={{ backgroundColor: member.color }} // Add this line to set the background color
        >
          <img className="member-image" src={member.imageSrc} alt={member.name} />
          <div className="member-info px-4 pt-1 sm:px-0 sm:pt-1">
            <h3>{member.name}</h3>
            <p>{member.role}</p>
            <div className="social-links">
              <a href={member.linkedin} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faLinkedin} className="social-icon" />
              </a>
              <a href={member.email} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faEnvelope} className="social-icon" />
              </a>
            </div>
          </div>
        </div>
        
        ))}
      </div>
    </div>
  );
};

export default TeamPage;
