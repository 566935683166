import React, { createContext, useContext, useState, ReactNode } from 'react';

interface StateContextType {
    menuOpen: boolean;
    setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const StateContext = createContext<StateContextType | undefined>(undefined);

export const useStateContext = () => {
    const context = useContext(StateContext);
    if (!context) {
        throw new Error('useStateContext must be used within a StateProvider');
    }
    return context;
};

export const StateProvider = ({ children }: { children: ReactNode }) => {

    const [menuOpen, setMenuOpen] = useState(false)

    return (
        <StateContext.Provider value={{ menuOpen , setMenuOpen }}>
            {children}
        </StateContext.Provider>
    )
}
