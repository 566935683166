import { BrowserRouter, Routes, Route } from 'react-router-dom'; 
import Navbar from './components/rishi/Navbar.tsx'; // Adjust the path as necessary 
import Blog from './pages/Blog'; 
import Blog_Page from './pages/Blog_Page'; 
import Main from './pages/main'; 
import TeamPage from "./components/Saksham/TeamPage"; 
import Menu from './components/rishi/Menu.tsx'; 
import Events from './pages/Events';
import { useStateContext } from './context/index.tsx'; 
import Bottom_Fixed from './components/rishi/Bottom-Fixed.tsx';
import ScrollToTop from './components/rishi/ScrollToTop.tsx';
 
function App() { 
 
  const { menuOpen } = useStateContext() 
 
  return ( 
    <BrowserRouter> 
          <ScrollToTop />
      <Navbar /> {/* Navbar will be shown on all pages */} 
      {menuOpen && 
      <div className="mt-[8vh]"> 
      <Menu /> 
    </div>} {/* Conditionally render Menu based on menuOpen */} 
      <Routes> 
        <Route path='/' element={!menuOpen && <Main />} /> 
        <Route path="/blog" element={!menuOpen && (<div className='mt-[8vh]'> <Blog /></div>) } /> 
        <Route path="/BlogPage" element={!menuOpen &&(<div className='mt-[8vh]'>   <Blog_Page /> </div>)} /> 
        <Route path='/team' element={!menuOpen &&  <TeamPage />} /> 
        <Route path="/main" element={!menuOpen &&  <Main />} /> 
        <Route path="/initiatives" element={!menuOpen && <Events />} />
      </Routes> 
      {!menuOpen &&<Bottom_Fixed />} 
    </BrowserRouter> 
  ); 
} 
 
export default App;