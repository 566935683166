/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import SVG_Path from './svg.tsx';

const Hero = () => {
    const changeme = useRef<HTMLSpanElement>(null);
    const svgRef = useRef<SVGSVGElement>(null);

    const texts = ["vision","passion","strategy","creativity","resilience","boldness"];
    let textIndex = 0;

    const heading1 = useRef<HTMLDivElement>(null);
    const heading2 = useRef<HTMLDivElement>(null);
    const heading3 = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (changeme.current) {
            changeme.current.textContent = texts[0];
        }

        if (svgRef.current) {
            const path = svgRef.current.querySelector('path');
            if (path) {
                const length = path.getTotalLength();
                gsap.set(path, { strokeDasharray: length, strokeDashoffset: length });
            }
        }

        const tl = gsap.timeline();

        if (svgRef.current)
        tl.fromTo([heading1.current, heading2.current, heading3.current],
            { opacity: 0, y: 80 },
            { opacity: 1, y: 0, duration: 0.75, stagger: 0.1 })
            .to(svgRef.current.querySelector('path'), {
                strokeDashoffset: 0,
                duration: 1,
                ease: 'power1.inOut'
            })
            .call(() => {
                const textTL = gsap.timeline({ repeat: -1 });

                textTL
                    .fromTo(changeme.current,
                        { y: 0, opacity: 1  },
                        { opacity: 0, y: -50, duration: 0.7, onComplete: updateText })
                    .fromTo(changeme.current,
                        { opacity: 0, y: 50 },
                        { opacity: 1, y: 0 , duration: 0.7}
                    );
            });
    }, []);

    const updateText = () => {
        if (changeme.current) {
            changeme.current.textContent = texts[textIndex];
            textIndex = (textIndex + 1) % texts.length;
        }
    };

    return (
        <div className='xl:h-[92vh] lg:h-[82vh] md:h-[75vh] sm:h-[68vh] h-[60vh] z-10 w-[100%] bg-white flex items-center'>
            <div className='xl:text-8xl lg:text-7xl md:text-6xl sm:text-5xl text-[2rem] w-full sm:ml-8 ml-2 relative flex flex-col'>
                <div className='overflow-hidden sm:pb-4 mt-56'>
                    <div ref={heading1} className='hero-text relative block'>
                        Creating&nbsp;
                        <span className='relative inline-block'>
                            <SVG_Path className='absolute inset-0' ref={svgRef} />
                            <span className='hero-text relative inline-block'>entrepreneurs</span>
                        </span>
                    </div>
                </div>
                <div className='overflow-hidden sm:pb-4 sm:-mb-4'>
                    <div ref={heading2} className='hero-text relative block'>
                        who redefine industries
                    </div>
                </div>
                <div className='overflow-hidden sm:pb-4 sm:-mb-4'>
                    <div ref={heading3} className='hero-text relative block'>
                        <span>through&nbsp;</span>
                        <span className='inline-block relative' ref={changeme}></span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Hero;


/**
 * 
 * import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';

const Menu = () => {
    const SameDiv = ({ Text }) => {
        const textRef = useRef<HTMLDivElement>(null);
        const hiddenTextRef = useRef<HTMLDivElement>(null);

        useEffect(() => {
            const textElement = textRef.current;
            const hiddenTextElement = hiddenTextRef.current;

            if (textElement && hiddenTextElement) {
                const textContent = textElement.textContent;
                if (textContent) {
                    const splittedText = textContent.split("");
                    let clutter = "";
                    splittedText.forEach(function (elem) {
                        clutter += `<span class="a">${elem}</span>`;
                    });
                    textElement.innerHTML = clutter;
                }
                const hiddenTextContent = hiddenTextElement.textContent;
                if (hiddenTextContent) {
                    const splittedText = hiddenTextContent.split("");
                    let clutter = "";
                    splittedText.forEach(function (elem) {
                        clutter += `<span class="a">${elem}</span>`;
                    });
                    hiddenTextElement.innerHTML = clutter;
                }

                // Set initial CSS styles for hiddenTextRef
                gsap.set(hiddenTextElement, { opacity: 0, y: 20 });

                const hoverAnimation = gsap.timeline({ paused: true });
                hoverAnimation
                    .to(textElement.querySelectorAll('.a'), {
                        y: -20,
                        opacity: 0,
                        duration: 0.15,
                        stagger: 0.025
                    })
                    .fromTo(hiddenTextElement, {
                        opacity: 0,
                        y: 0,
                        duration: 0.15,
                        stagger: 0.025
                    },
                        {
                            opacity: 1,
                            y: -30,
                            duration: 0.15,
                            stagger: 0.025
                        }) // Start this animation at the same time

                const handleMouseEnter = () => hoverAnimation.play();
                const handleMouseLeave = () => {
                    hoverAnimation.reverse();
                    hoverAnimation.seek(0);
                };

                textElement.addEventListener('mouseenter', handleMouseEnter);
                textElement.addEventListener('mouseleave', handleMouseLeave);

                return () => {
                    textElement.removeEventListener('mouseenter', handleMouseEnter);
                    textElement.removeEventListener('mouseleave', handleMouseLeave);
                };
            }
        }, []);

        return (
            <div className='flex items-center justify-between w-[45vw]'>
                <div className='flex flex-col'>
                    <h1 ref={textRef} className='text-black overflow-hidden font-bold text-4xl'>
                        <span className=''>{Text}</span>
                    </h1>
                    <h1 ref={hiddenTextRef} className='text-black overflow-hidden font-bold text-4xl'>
                        <span className=''>{Text}</span>
                    </h1>
                </div>

                <div className='flex items-center mr-8 justify-center'>
                    <div className='rounded-full w-6 h-6 border-black border-2 flex items-center justify-center'>
                        <div className='rounded-full bg-gray-700 h-1 w-1'></div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className='w-[100vw] h-[92vh] bg-yellow-300 flex'>
            <div className='w-[50vw] h-full flex flex-col'>
                <div className='h-[50vh]'></div>
                <div className='h-[50vh] flex flex-col g w-full items-center justify-center'>
                    <SameDiv Text="Way Of Working" />
                    <SameDiv Text="Projects" />
                    <SameDiv Text="About" />
                    <SameDiv Text="Contact" />
                </div>
            </div>
            <div className='img w-[47vw] top-0 mt-2'>
                <img src='/menuImages/about.webp' alt="img" />
            </div>
        </div>
    );
};

export default Menu;

 */