import React from 'react';
import { FaHeart } from "react-icons/fa";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { useNavigate } from 'react-router-dom';



const BlogCard = ({ imageUrl, title, tags, desc, navigateTo, color, author }) => {

    const navigate = useNavigate()

    return (
        <div className='flex   my-2  flex-col lg:flex-row-reverse lg:justify-between max-w-[950px] lg:min-h-[390px] p-3 lg:px-6 lg:py-4 lg:w-full border-solid border border-greey2 rounded-xl relative'

            style={{ backgroundColor: color, userSelect: "none" }}
        >
            <div className='flex flex-row justify-center lg:items-center'>

                <img src={imageUrl}
                    style={{ height: "250px", width: "400px", borderRadius: "15px" }} />

            </div>

            {/* Text Section*/}
            <div className='w-full py-5 lg:w-3/5 lg:py-5 flex-col justify-start'>



                <p
                    className='text-[#101828] text-[30px] font-medium  select-text font-clash line leading-less'
                    style={{
                        letterSpacing: "0.6px",
                        lineHeight: "1em"
                    }}
                >{title}</p>

                <div className='py-3'>

                    {tags?.map((tag) => (
                        <span className='mr-2 px-4 py-2 rounded-xl text-center text-default  bg-whitte1  backdrop-blur-sm font-normal font-satoshi '

                        >{tag}</span>

                    ))}


                </div>

                {/* Description */}
                <p className='text-left font-satoshi text-[#475467] font-[400] text-[20px]'
                    style={{
                        minHeight: "60px", textOverflow: "ellipsis", overflow: "hidden",
                        display: "-webkit-box", WebkitLineClamp: 5, WebkitBoxOrient: "vertical",
                        letterSpacing: "0em",
                        lineHeight: "1.4em",

                    }}
                >{desc}</p>



                <span className='   flex flex-row items-center cursor-pointer mt-2 h-9  text-center font-clash text-default font-bold bg-whitte rounded-ten px-3 py-2 gap-1 backdrop-blur-sm max-w-32 lg:absolute'

                    style={{

                        left: "42px",
                        bottom: "25px"
                    }}


                    onClick={() => { navigate("/blogPage", { state: {navigateTo,author} }) }}



                >Read More<MdKeyboardDoubleArrowRight color='black' fontSize={"18px"} /></span>








            </div>


        </div>
    );
}

export default BlogCard;