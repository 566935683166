import React, { useRef } from 'react';

const Bottom_Fixed = () => {


  return (
    // <div className="w-[100vw] fixed bottom-0 -z-10 ">
    //   <div className='h-[2.5vh] md:h-[4vh] bg-yellowbg'></div>
    //   <div className='absolute bottom-8 w-[100vw] z-[-100] flex items-center justify-center'>
    //     <div className='hidden md:flex bg-yellowbg min-h-[10vh] w-3/6'></div>
    //     <div className='bg-greyc1 w-[100vw] min-h-[10vh] md:w-3/6'></div>
    //   </div>      
    // </div>
    <div className='w-[100vw] fixed bottom-0 h-[2.5vh] md:h-[4vh] bg-yellowbg z-20'></div>
  );
};

export default Bottom_Fixed;
