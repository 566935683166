import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ParallaxPage.css';


const splitTextIntoLines = (text, delayOffset = 0) => {
  const lines = text.split('\n');
  return lines.map((line, index) => (
    <span
      key={index}
      className="animated-line"
      style={{ display: 'block', animationDelay: `${(index * 0.1) + delayOffset}s` }}
    >
      {line.split(/(\s+)/).map((word, wordIndex) => (
        <React.Fragment key={wordIndex}>

          <span className="animated-word">{word}</span>
          {wordIndex < line.split(' ').length - 1 && <span>{' '}</span>} {/* Insert a regular space */}
        </React.Fragment>

      ))}
    </span>
  ));
};

const ParallaxPage = () => {
  const [isHovered, setIsHovered] = useState(false); // Track hover state
  const [isClicked, setIsClicked] = useState(false); // Track click state
  const [isHoveredTeam, setIsHoveredTeam] = useState(false);
  const [isHoveredBlogs, setIsHoveredBlogs] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);
  const [isTablet, setIsTablet] = useState(window.innerWidth > 500 && window.innerWidth <= 990);
  const [parallaxActive, setParallaxActive] = useState(false);
  const parallaxRef = useRef();

  const navigate = useNavigate();

  const updateScreenSize = () => {
    setIsMobile(window.innerWidth <= 500);
    setIsTablet(window.innerWidth > 500 && window.innerWidth <= 990);
  };

  useEffect(() => {
    window.addEventListener('resize', updateScreenSize);
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  useEffect(() => {
    const handleScroll = () => {

      const parallaxImage = document.querySelector('.parallax img');
      const parallaxContainer = document.querySelector('.parallax');
      if (parallaxImage && parallaxContainer) {
        let scrollPosition = window.pageYOffset;
        let containerOffset = parallaxContainer.getBoundingClientRect().top + window.pageYOffset;
        let speedFactor = 0.1; // Adjust speed factor for image
        let imageOffset = (scrollPosition - containerOffset) * speedFactor;

        // Move the image in the opposite direction of scroll
        parallaxImage.style.transform = `translateY(${imageOffset * 0.5}px)`;

      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [parallaxActive]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setParallaxActive(true);
        } else {
          setParallaxActive(false);
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );
    if (parallaxRef.current) {
      observer.observe(parallaxRef.current);
    }
    return () => {
      if (parallaxRef.current) {
        observer.unobserve(parallaxRef.current);
      }
    };
  }, []);
  const handleParallaxClick = () => {
    setIsClicked(true); // Remove grayscale permanently on click
  };

  const handleMouseEnter = () => {
    if (!isClicked) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isClicked) {
      setIsHovered(false); // Return to grayscale when not clicked
    }
  };
  const styles = {
    container: {
      paddingTop: isMobile ? '0' : '50px',
      display: 'flex',
      flexDirection: isMobile || isTablet ? 'column' : 'row',
      alignItems: 'center',
      justifyContent: 'start',
      height: '100vh',
      // overflow: 'hidden',
    },
    parallax: {
      marginLeft: '20px',
      
      marginBottom: '20px',
      
      position: 'relative',
      width: '90%',
      height: isTablet ? '40%' : '80%',
      overflow: 'hidden',
      flex: isMobile ? 0 : 1,
      paddingRight: '20px',
      justifyContent:'auto',
      
    },
    parallaxImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      transition: 'transform 0.5s ease-out, filter 0.5s ease-out', // Added transition for filter
      justifyContent: 'center',
      transform: isHovered ? 'scale(1.02)' : 'scale(1)', // Zoom in on hover
      filter: isHovered || isClicked ? 'grayscale(0%)' : 'grayscale(50%)', // Grayscale effect
    },
    content: {
      position: 'relative',
      width: isMobile || isTablet ? '90%' : '50%',
      padding: '20px',
      zIndex: 2,
      textAlign: 'center',
      marginTop: isTablet ? '20px' : '0',
      display: 'flex',
      flexDirection: 'column',
      paddingTop:'5px'
    },
    heading: {
      fontFamily: 'Arial, sans-serif',
      fontSize: isMobile ? '22.1849px' : '29.4px',
      marginTop: '10px',
      fontWeight: 'bold',
      color: '#212529',
      marginBottom: '40px', // Increased gap below the heading
      lineHeight: '1.2',
      textAlign: 'left',
      width: '100%',
    },
    paragraph: {
      fontFamily: 'Arial, sans-serif',
      fontSize: isMobile ? '16.6387px' : '18.9px',
      color: 'rgba(29, 29, 29, 1)',
      lineHeight: '1.7',
      textAlign: 'left', // Aligns the text to the left
      width: '100%', // Makes sure the paragraph spans the full width
      marginTop: '10px', // Optionally, add margin for spacing
      marginBottom: '50px',
    },
    buttonContainer: {
      textAlign: 'left', // Align buttons to the left
      marginBottom: '0px',
      width: '100%', // Ensure full width
    },
    projectButton: (isHovered) => ({
      padding: '15px 30px',
      backgroundColor: isHovered ? '#ffffff' : 'transparent',
      border: '2px solid black',
      borderRadius: '30px',
      color: '#000',
      fontSize: isMobile ? '14px' : '16px',
      cursor: 'pointer',
      outline: 'none',
      transition: 'background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease',
      boxShadow: isHovered ? '0 4px 15px rgba(0,0,0,0.2)' : 'none',
      margin: '10px',
      display: 'inline-block', // Ensures buttons don't take full width
    }),
  };

  return (
    <div className='bg-white'>
      <div style={styles.container} ref={parallaxRef}>
        <div className="parallax" style={styles.parallax} onMouseEnter={handleMouseEnter}
  onMouseLeave={handleMouseLeave}
  onClick={handleParallaxClick}>
          <img src="edc group image.jpeg" alt="Profile" style={styles.parallaxImage} />
        </div>
        <div style={styles.content}>
          <h1 style={styles.heading}>
            {splitTextIntoLines('Known More ABOUT eDC')}
          </h1>
          <p style={styles.paragraph}>
            {splitTextIntoLines(
              'The Entrepreneurship Development Cell (eDC) of IIT Delhi, established in January 2007, is committed to fostering a culture of innovation and entrepreneurship within the student community. With a focus on providing the right resources, mentorship, and support, EDC aims to bridge the gap between innovative ideas and the real-world startup ecosystem.eDC also focuses on cultivating a startup-friendly environment on campus, where creativity, critical thinking, and collaborative efforts thrive. Through a combination of hands-on experiences and expert guidance, eDC empowers students to become change-makers, driving the future of innovation and business.',
              0.5
            )}
          </p>
          <div style={styles.buttonContainer} className='pb-2'>
            <button 
              style={styles.projectButton(isHoveredTeam)}
              onMouseEnter={() => setIsHoveredTeam(true)}
              onMouseLeave={() => setIsHoveredTeam(false)}
              onClick={() => navigate('/team')}
            >
              Meet Our Team
            </button>
            <button
              style={styles.projectButton(isHoveredBlogs)}
              onMouseEnter={() => setIsHoveredBlogs(true)}
              onMouseLeave={() => setIsHoveredBlogs(false)}
              onClick={() => navigate('/blog')}
            >
              Read Our Blogs
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParallaxPage;