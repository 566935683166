import React, { forwardRef } from 'react';

const SVG_Path = forwardRef<SVGSVGElement, { className?: string }>((props, ref) => {
    return (
        <svg
            ref={ref}
            className={props.className}
            width="100%"
            height="auto"
            viewBox="0 0 564 116"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                id="path"
                d="M338.128 5.24093C308.535 2.95059 280.033 1.05665 250.031 2.5074C240.659 2.96057 205.923 5.42153 196.947 6.67963C152.759 12.8733 113.575 27.1523 76.5913 40.2733C61.8949 45.4873 19.8418 59.7438 6.77766 69.6947C3.57558 72.1337 2.28193 75.4472 3.38933 78.3269C4.06158 80.0749 21.5903 91.5667 23.8605 92.6419C35.2817 98.0513 48.1112 100.343 63.1086 102.425C112.569 109.291 165.045 112.825 216.148 113.683C274.102 114.655 327.604 113.423 385.352 109.547C437 106.08 480.714 102.468 527.38 91.2032C541.343 87.8324 564.191 82.3128 560.628 71.8527C559.36 68.1307 554.525 64.8774 549.898 61.9257C543.479 57.8305 508.797 41.5645 501.05 38.4749C483.87 31.6239 470.985 28.0955 450.225 24.7353C411.509 18.469 368.346 14.2315 327.68 14.6644C285.803 15.1102 251.657 18.8212 210.713 23.6563C191.309 25.9477 172.028 28.5254 152.899 31.3533C29.079 49.6579 173.384 28.9817 87.8151 43.4384"
                stroke="#F472FF"
                strokeWidth="4"
                strokeLinecap="round"
            />
        </svg>
    );
});

export default SVG_Path;
