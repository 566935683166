import React, { useState } from 'react';
import Event_Card from '../components/keshav/Event_card';
import Navbar from '../components/rishi/Navbar.tsx';

const Event = ({ cardsToShow }) => {
  const [hoveredCard, setHoveredCard] = useState(null);

  const eventData = [
    {
      id: 1,
      imageUrl: '/events/e2.jpeg',
      color: '#ccf3ff',
      title: 'MAD FOR AD',
      desc: 'MadForAd—an unforgettable inter-hostel ad-making event! Hostels showcase their advertising prowess by crafting compelling ads for assigned companies. The excitement peaks as teams pitch their creations to an esteemed panel of judges, who carefully evaluate the campaigns.',
    },
    {
      id: 2,
      imageUrl: '/events/e3.jpeg',
      color: '#ebe0ff',
      title: 'MUSKEETERS',
      desc: 'Musketeers is our Fresher Startup Building Competition! Unleash your creativity as freshers form teams, ideate, and craft startups that tackle real-life challenges. An expert panel of judges awaits to evaluate your pitches.',
    },
    {
      id: 3,
      imageUrl: '/events/e4.jpeg',
      color: '#c5ffb8',
      title: 'BECON',
      desc: 'IIT Delhi’s BECON: The most exciting and creative startup exhibitions, keynotes, panel discussions, participate in workshops, pitching stages, hackathons, and competitions, with great startup ideas and VC firms throughout the event.',
    },
    {
      id: 4,
      imageUrl: '/events/e1.jpeg',
      color: '#fff2d4',
      title: 'FOUNDERS NET',
      desc: 'eDC is inviting founders to join their exclusive founder-only community. This community is aimed at bringing together like-minded individuals who are passionate about entrepreneurship and innovation.',
    },
    {
      id: 5,
      imageUrl: '/events/e5.jpeg',
      color: '#ccf3ff',
      title: 'MOONSHOT',
      desc: 'Moonshot Event is Asia’s biggest student-run startup where innovative minds collide! Dive into the entrepreneurial waters and make a splash like never before. Calling all visionaries, entrepreneurs, and dreamers – this is your moment!',
    },
    {
      id: 6,
      imageUrl: '/events/e6.jpeg',
      color: '#ebe0ff',
      title: 'OPPORTUNITY PORTAL',
      desc: 'The eDC Opportunity Portal serves as a transformative platform connecting high-growth startups with top-tier interns from the prestigious institution. Designed as a skill development program, this initiative focuses on providing startups with access to innovative students.',
    },
  ];

  // Slice the event data array to limit the number of cards displayed
  const eventsToShow = eventData.slice(0, cardsToShow);

  return (
    <div className='text-white'>
      {/* If you have a Navbar component, include it here */}
      <div style={{ backgroundImage: '/Audience.ARW' }}>
        {/* Background image content */}
      </div>
      <h1
        className='font-Intertight text-black'
        style={{
          textAlign: 'center',
          fontSize: '30px',
          fontWeight: 'bold',
          letterSpacing: '4px',
        }}
      >
        Events
      </h1>

      <div className='py-5 px-3 grid grid-cols-1 gap-6 md:grid-cols-2 mx-auto justify-items-center'>
        {eventsToShow.map((event) => (
          <Event_Card
            key={event.id}
            id={event.id}
            imageUrl={event.imageUrl}
            color={event.color}
            title={event.title}
            desc={event.desc}
            onMouseEnter={() => setHoveredCard(event.id)}
            onMouseLeave={() => setHoveredCard(null)}
            isHovered={hoveredCard === event.id}
            isOtherHovered={hoveredCard !== null && hoveredCard !== event.id}
          />
        ))}
      </div>
    </div>
  );
};

export default Event;
