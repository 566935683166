import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { MdKeyboardBackspace } from "react-icons/md";
import { useNavigate, useLocation } from 'react-router-dom';
import "../components/keshav/Blog.css"
import { TiArrowLeft } from "react-icons/ti";
import Navbar from '../components/rishi/Navbar.tsx'

const Blog_Page = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const container = useRef(null);
    const [BlogData, SetBlogData] = useState("");
    const blockRefs = useRef([]);
    const [Width, setWidth] = useState(0);
    const { author } = location.state || {}

    const defaultContent = ["Overview", "Problem", "Target User", "Goals", "Current Scenario", "Research", "Features"];


    const handleScroll = () => {
        if (container.current) {
            const scrollTop = container.current.scrollTop;
            const containerHeight = container.current.clientHeight;

            // Update the progress bar
            const scrollHeight = container.current.scrollHeight;
            const scrolledPercentage = (scrollTop / (scrollHeight - containerHeight)) * 100;
            setWidth(scrolledPercentage);

            // Update the highlighted sidebar item
            blockRefs.current.forEach((block, index) => {
                if (block) {
                    const blockTop = block.offsetTop;
                    const blockHeight = block.offsetHeight;
                    const blockInView = scrollTop + containerHeight / 2 >= blockTop && scrollTop + containerHeight / 2 < blockTop + blockHeight;

                }
            });
        }
    };

    const handleSidebarClick = (index) => {
        blockRefs.current[index]?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        const loadBlogData = async () => {

            console.log("Hello")
            try {
                const Blog = await import(`../Blogs/${location.state?.navigateTo}`);
                SetBlogData(Blog.default || Blog);
            } catch (error) {
                console.error('Failed to load blog data:', error);
            }
        };
        loadBlogData();
    }, [location.state]);

    const list = BlogData?.content ? BlogData.content : defaultContent;





    return (

        <div className='flex flex-col w-full h-screen'>
            {/* Progress bar */}
            <div className='bg-mypurple ' style={{ width: `${Width || 0}%`, height: "3px" }}>
                <span style={{ opacity: 0 }}>Progress</span>
            </div>
            <div className='mt-8 h-full'>
                {/* Back button for mobile */}
{/* 
                <div className='flex flex-row items-center left-6 top-[82px] gap-2 justify-center absolute  hover:bg-hover p-2 lg:hidden font-satoshi cursor-pointer bg-["#f3f6fd] z-[1000]'
                    style={{
                        border: "1px solid #b3bcce", borderRadius: "10px", width: "60px"
                    }}

                    onClick={() => { navigate(-1); }}>

                    <TiArrowLeft size={"12px"} style={{ fontWeight: "400" }} />
                </div> */}


                <div className='px-1 flex flex-row py-0 lg:px-12 h-full'>
                    {/* Sidebar */}

                    <div className=' flex-col gap-4 px-3 hidden lg:flex font-satoshi justify-start'
                        style={{ width: "300px", height: "100%", }}>


                        {/* Back Button */}
                        <div className='flex flex-row items-center gap-2 justify-center hover:bg-hover'
                            style={{ border: "1px solid #b3bcce", cursor: "pointer", backgroundColor: "#f3f6fd", borderRadius: "10px", padding: "8px", width: "110px" }}

                            onClick={() => { navigate(-1); }}>

                            <TiArrowLeft size={"24px"} />
                            <span style={{ fontSize: "21px", fontWeight: "400" }}>Back</span>

                        </div>

                        {/* Sidebar Items */}
                        <div className='flex flex-col items-start gap-2'>
                            <span style={{ color: "#7f56d9", fontWeight: "bold", fontSize: "18px" }}>Content</span>
                            {list.map((text, index) => (
                                <Sidetext index={index} name={text} handleClick={handleSidebarClick} key={index} />
                            ))}
                        </div>
                    </div>


                    {/* Content */}
                    <div className='flex flex-col px-5 justify-start mt-5 lg:mt-0' style={{ width: "100%", height: "92vh", overflowY: "scroll", scrollbarWidth: "none" }} ref={container} onScroll={handleScroll}>
                        <div className='pb-2 text-left lg:text-center font-Roboto text-medium lg:text-large text-mypurple1 font-bold2'
                        >
                            <div className='font-clash'
                                style={{ letterSpacing: "0.7px" }}
                            >
                                <span>{BlogData.title}</span>



                            </div>
                      {author &&   <div
                                className='text-black font-satoshi font-semibold text-[15px] text-end'
                            >By:- {author}</div>}
                        </div> 


                        {list.map((title, index) => (
                            <Blocks key={index}
                                title={title}
                                content={BlogData[title.replace(/\s+/g, '_')]}
                                blockRef={el => (blockRefs.current[index] = el)} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Blog_Page;




const Sidetext = ({ index, name, handleClick }) => {
    return (
        <span
            className='hover:text-mypurple font-satoshi cursor-pointer font-normal text-whitte2 
        '
            onClick={() => handleClick(index)}>
            {name}
        </span>
    );
};

const Blocks = ({ title, content, blockRef }) => {
    const styles = {
        title: {
            fontWeight: "600", fontSize: "18px", paddingBottom: "8px",

        },
        list: {
            listStylePosition: "inside",
            color: "#272f3d",
        },
        bullet: {

            position: "relative",
            top: "12px",
            maxWidth: "8px",
            maxHeight: "8px",
            backgroundColor: "#272f3d",
            borderRadius: "50%",
            marginRight: "20px",
            minWidth: "5px",
            minHeight: "5px",

        },
    };




    return (

        <div className='flex flex-col justify-start'>


            {content?.imgurl &&
                (<div className='flex flex-row justify-center my-3'
                    style={{
                        border: "1px solid #d9d9d9", outline: "1px solid #d9d9d9", 
                        outlineOffset: "+3px",overflow:"hidden" 
                    }}
                >
                    <img src={content?.imgurl} alt="img" className='zoom w-full 
                    h-full'
                    
                    />
                </div>)}

            <div className='py-3 flex flex-col justify-start lg:flex-row lg:items-start w-full gap-5 lg:gap-8'
                ref={blockRef}>

                {/* left title */}
                <div className='w-full lg:w-1/4 font-Roboto font-bold2 text-medium2'>
                    <span className='font-clash font-bold2 text-medium2' >{title}</span>
                </div>

                {/* right part */}

                <div className='w-full text-small font-satoshi lg:text-medium2 lg:w-3/4 text-greey leading-less1 font-normal ' >

                    <p
                        className='text-greey font-bold underline mb-3 font-satoshi'
                        style={{ display: !content?.paraTitle && "none" }}
                    >{content?.paraTitle && content?.paraTitle}</p>

                    {content?.text &&
                        content.text.map((e) => (
                            <p className='mb-3'>{`${e}`}</p>
                        ))
                    }


                    {/* Displaying of List  */}
                    {content?.list && (
                        <div className='flex flex-col my-3'>

                            <span className='py-1 font-clash'
                                style={{

                                    fontWeight: typeof (content?.list.heading) === "object" && "600",
                                    fontSize: typeof (content?.list.heading) === "object" && "17px",
                                    color: typeof (content?.list.heading) === "object" && "#272f3d",

                                }}
                            >
                                {content?.list.heading}
                            </span>

                            <ul style={styles.list}>
                                {content?.list?.items?.map((item, index) => (
                                    <li
                                        className='pb-2 flex flex-row justify-start items-start'
                                        key={index}>
                                        <span style={styles.bullet}></span>
                                        {item}</li>
                                ))}
                            </ul>

                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

