import React from 'react'
import BlogCard from "../components/keshav/card.js"
import Navbar from '../components/rishi/Navbar.tsx'

const Blog = () => {

    const url1 = "https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2Fcf62b46c-ab62-4e39-a2c2-b7f83be1d3bf_1080x1080.heic"


    const url2 = "https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F843ed693-b52b-4ea2-bd02-dfab0aed333a_1080x1080.heic"

    const url3 = "https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F58c4d082-c541-4216-bf1b-6725444fc9d4_1080x1080.heic"




    const url4 = "https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2Fab5e142c-3538-4148-825e-f575fa585df6_1080x1080.heic"


    const url5 = "https://substackcdn.com/image/fetch/w_320,h_213,c_fill,f_webp,q_auto:good,fl_progressive:steep,g_center/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F76f4c3f4-cf04-41c2-ad02-dca1ea1e8383_1080x1080.heic"


    const url6 = "https://substackcdn.com/image/fetch/w_1456,c_limit,f_webp,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2Fb95bd046-20ba-4b51-a4ae-f3cfc7d20946_1200x1200.heic"


    const url7 = "https://substackcdn.com/image/fetch/w_1456,c_limit,f_webp,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F5fed41e5-323a-407a-8684-20fffaee38ff_992x748.jpeg"

    const url8 = "https://substackcdn.com/image/fetch/w_1456,c_limit,f_webp,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2Fc20021b6-4039-4c6b-8481-99f2e0a3eb2a_1080x1080.heic"









    return (
        <div className="px-4  flex flex-col justify-center items-center mb-8">

            <h1 className='font-Babus'
                style={{ textAlign: "center", fontSize: "34px", fontWeight: "bold",letterSpacing:"4px" }}
            >Blogs</h1>

            <div className="lfex flex-col justify-center items-center gap-0">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-4 lg:gap-0">
                    <BlogCard
                        imageUrl={url8}
                        title={"STARTUP OF THE MONTH: INSPIRE AI"}
                        tags={["AI", "Marketing"]}
                        desc={"InspireAI is revolutionizing content creation by not just assisting users in writing but ensuring they form the habit of posting consistently. Launched by IITD students Krishant Sethia and Vinayak Agarwal in February 2024, the app simplifies the process of maintaining an active online presence, particularly on LinkedIn, using AI-driven analytics to optimize posts for maximum impact. InspireAI is designed to help users reach the right audience at the right time."}
                        navigateTo={"eigth"}
                        color={"#ccf3ff"}
                        author={"Arnav Dixit"}
                    />
                    <BlogCard
                        imageUrl={url1}
                        title={"Innovative Space-tech Startups and Why You Should Care"}
                        tags={["Space", "Technology"]}
                        desc={"Space tech is evolving beyond traditional missions and satellites, with innovative startups exploring space farming and biotech. These advancements have the potential to revolutionize agriculture and medical research on Earth, using space-based environments to address global challenges like food shortages and regenerative medicine"}
                        navigateTo={"first"}
                        color={"#ebe0ff"}
                        author={"Sriya Reddy Ryala"}
                    />
                    <BlogCard
                        imageUrl={url2}
                        title={"Brain Breakthroughs: How Brain-Computer Interfaces Are Redefining Human-Tech Interaction"}
                        tags={["BCI", "HCI"]}
                        desc={"BCIs are revolutionizing Human-Computer Interaction by enabling direct communication between the brain and devices, with applications in medical rehabilitation,immersive virtual experiences."}
                        navigateTo={"second"}
                        color={"#ccf3ff"}
                        author={"Pragun Pudukoli"}
                    />
                    <BlogCard
                        imageUrl={url3}
                        title={"ECONOMIC DIPLOMACY: THE FINE BALANCE OF TRADE POLICIES AND GEOPOLITICS"}
                        tags={["Economics", "Geopolitics"]}
                        desc={"A case study very rightly reveals the importance of leveraging this economic clout. During the cold war two neighboring east European countries."}
                        navigateTo={"third"}
                        color={"#c5ffb8"}
                        author={"Arnav Dixit"}
                    />
                    <BlogCard
                        imageUrl={url4}
                        title={"Cheers to Deception? How Liquor Brands Navigate India's Advertising Ban"}
                        tags={["Alcohol","Branding"]}
                        desc={"Surrogate advertising helps alcohol brands in India promote their products indirectly by advertising other goods like soda or water. This strategy exploits legal loopholes, using brand extensions, event sponsorships, product placements, and social media to maintain visibility while complying with advertising restrictions."}
                        navigateTo={"fourth"}
                        color={"#fff2d4"}
                        author={"Pragun Pudukoli"}
                    />
                    <BlogCard
                        imageUrl={url5}
                        title={"MONACO: PLAYGROUND OF THE ELITE"}
                        tags={["Motorsport", "Entrepreneurship"]}
                        desc={"The Monaco Grand Prix, established in 1929, epitomizes luxury and boosts the local economy through tourism and sponsorships. Monaco's expensive real estate market and strong financial sector further attract high-net-worth individuals and support entrepreneurial ventures, making it a unique hub for wealth and opportunity."}
                        navigateTo={"fifth"}
                        color={"#c5ffb3"}
                        author={"Sriya Reddy Ryala"}
                    />
                    <BlogCard
                        imageUrl={url6}
                        title={"THE ESG REVOLUTION: A BEGINNER'S GUIDE TO SUSTAINABLE INVESTING IN INDIA"}
                        tags={["ESG","CorporateEthics"]}
                        desc={"ESG (Environmental, Social, and Governance) scores act as a report card for companies, evaluating their environmental impact, treatment of stakeholders, and governance practices. Calculated by agencies like Sustainalytics, higher scores indicate better performance and resilience. Investors can use these scores to align their investments with their values while potentially achieving better financial returns, as studies link strong ESG practices to improved financial performance."}
                        navigateTo={"sixth"}
                        color={"#fff2d4"}
                        author={"Kamakshi Garg"}
                    />
                    <BlogCard
                        imageUrl={url7}
                        title={"Balancing Dreams and Reality: The Deferred Placement Perspective"}
                        tags={["IITDelhi", "Entrepreneurship"]}
                        desc={"This newsletter introduces IIT Delhi's \"Deferred Placements,\" allowing students to delay final-year placements to pursue startups. Implemented since 2015, it offers a safety net for entrepreneurial ventures, enabling students to return to placements if their startup fails within two years. The program also allows students to earn DPE units during a semester break while working on their projects with faculty support."}
                        navigateTo={"seventh"}
                        color={"#c5ffb3"}
                        author={""}
                    />
                </div>
            </div>


        </div>
    )
}

export default Blog

