/* eslint-disable no-mixed-operators */
import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { gsap } from 'gsap';

const Footer = () => {

  const SameDiv = ({ Text, path , href }) => {
    const textRef = useRef();
    const circle = useRef();
    const arrowRef = useRef();
    const containerRef = useRef();

    useEffect(() => {
      const textElement = textRef.current;
      const arrowElement = arrowRef.current;
      const circleElement = circle.current;
      const containerElement = containerRef.current;

      if (textElement && containerElement) {
        const textContent = textElement.textContent;
        if (textContent) {
            const splittedText = textContent.split('');
            let clutter = '';
            splittedText.forEach(function (elem) {
                if (elem === ' ') {
                    clutter += `<span class="char">&nbsp;</span>`;
                } else {
                    clutter += `<span class="char">${elem}</span>`;
                }
            });
            textElement.innerHTML = clutter;
        }

        const arrowAnimation = gsap.timeline({ paused: true });
        arrowAnimation
          .to(arrowElement, { x: 20, opacity: 0, duration: 0.1 }) // Use '!' to assert that arrowElement is not null
          .to(arrowElement, { x: -20, opacity: 0, duration: 0.05 })
          .to(arrowElement, { x: 0, opacity: 1, duration: 0.1 });

        const circleAnimation = gsap.timeline({ paused: true });
        circleAnimation.to(circleElement, {
          onStart: () => {
            if (arrowElement) {
              (arrowElement).style.stroke = 'white'; // Assert arrowElement as SVGSVGElement
            }
          },
          backgroundColor: 'black',
          scale: 1.13,
          duration: 0.4,
          onReverseComplete: () => {
            if (arrowElement) {
              (arrowElement).style.stroke = 'black';
            }
          },
        });

        function animateUp() {
          if (textElement)
            return gsap.to(textElement.querySelectorAll('.char'), {
              y: -50,              // Move up by 50px
              opacity: 0,          // Fade out
              duration: 0.5,       // Duration of 0.5 seconds
              stagger: 0.05        // Stagger each character by 0.05 seconds
            });
        }

        function animateCorrect() {
          if (textElement)
            return gsap.set(textElement.querySelectorAll('.char'), { y: 50, opacity: 0, stagger: 0.05 });
        }

        function animateDown() {
          if (textElement)
            return gsap.to(textElement.querySelectorAll('.char'), {
              y: 0,                // Move back to original position
              opacity: 1,          // Fade in
              duration: 0.5,       // Duration of 0.5 seconds
              stagger: 0.05        // Stagger each character by 0.05 seconds
            });
        }

        function handleHover() {
          animateUp();
          setTimeout(() => {
            animateCorrect();
            animateDown();
          }, 100);
        }

        const handleMouseEnter = () => {
          // Existing animations
          arrowAnimation.restart();
          circleAnimation.restart();
          handleHover();
        };

        const handleMouseLeave = () => {
          circleAnimation.reverse();
        };

        containerElement.addEventListener('mouseenter', handleMouseEnter);
        containerElement.addEventListener('mouseleave', handleMouseLeave);

        return () => {
          containerElement.removeEventListener('mouseenter', handleMouseEnter);
          containerElement.removeEventListener('mouseleave', handleMouseLeave);
        };
      }
    }, [Text, arrowRef, circle, containerRef, textRef]);

    const handleClick = () => {
      if(href){
          window.open(href, "_blank");
      }
    };

    return (
      <Link  to={path ? path : '/'}>
        <div  
          onClick={handleClick}
          ref={containerRef}
          className="flex items-center justify-between lg:w-[45vw] w-[95vw] md:w-[45vw]"
        >
          <h1
            ref={textRef}
            className="hero-text overflow-hidden text-black h-[7vh] font-bold text-3xl sm:text-5xl flex items-center justify-center"
          >
            <span>{Text}</span>
          </h1>
          <div className="flex items-center mr-8 justify-center">
            <div
              ref={circle}
              className="rounded-full overflow-hidden w-6 h-6 border-black border-[1px] flex flex-col items-center justify-center"
            >
              <svg
                ref={arrowRef}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1"
                stroke="black"
                className="w-4 h-4"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
              </svg>
            </div>
          </div>
        </div>
      </Link>
    );
  };


  return (
    <div>
      <div className="flex md:flex-row flex-col min-h-screen w-full">
        <div className="bg-yellowbg gap-20 md:gap-0 text-black font-bold4 w-full md:w-3/6 p-10 flex flex-col md:justify-between flex-grow">
          <nav>
            <div className="flex flex-col items-center justify-center gap-6 ml-2 mt-7">
              <SameDiv Text="Home" path="/main" />
              <SameDiv Text="Initiatives" path="/initiatives" />
              <SameDiv Text="Team" path="/team"/>
              <SameDiv Text="Knowledge Base" href='https://edciitd.notion.site' />
              <SameDiv Text="Blogs" path="/blog" />
              <SameDiv Text="Contact" path="/main#footer" />
            </div>
          </nav>
          <div className="text-left">
            <span className="flex items-center text-lg font-bold4 tracking-wider">
              <img src="/eDCLogo.svg" alt="edc-logo" className="h-20 w-20 rounded-full mr-2 mb-0" />
            </span>
          </div>
        </div>
        <div className="bg-greyc1 text-white w-full md:w-3/6  flex flex-col justify-between ">
          <div className='ml-9 mt-7 mr-6'>
            <h1 className=" text-xl sm:text-2xl lg:text-4xl mb-5 hero-text sm:w-64 w-[40vw]">Get to know each other.</h1>
            <p className="text-gray-400 text-base sm:text-lg lg:text-lg hero-text">
              <span className="text-white font-bold hero-text ">Want to start with a cup of coffee first?</span> Let's get acquainted and explore each other's mindset to clarify your project perspective. Completely without obligation of course.
            </p>
            <div className="md:mt-5 mt-12 lg:mt-10">
              <form>
                <div className="flex flex-col gap-8">
                  <div className="flex flex-col gap-2">
                    <label htmlFor="email" className="uppercase text-md text-gray-300 font-bold2">Email-id</label>
                    <input type="text" id="email" placeholder='Enter your email' name="email" className="bg-white text-black border border-black rounded px-2 py-2" />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label htmlFor="query" className="uppercase text-md text-gray-300 font-bold2">Send us a Message</label>
                    <textarea type="text" id="query" placeholder='Enter your message' name="query" className="bg-white text-black border border-black rounded px-2 py-1 h-28" />
                  </div>
                  <input type="submit" value="Send" className="h-8 w-20 text-default bg-white cursor-pointer hover:scale-105 transition-all border-black rounded text-black" />
                </div>
              </form>
            </div>
          <div className="flex justify-start items-center sm:mt-8 lg:mt-12 mt-8 ">
            <div className='flex flex-col gap-2 items-start justify-center w-full pb-2 border-t-[1px] border-b-[1px]'>
              <div className='flex items-center mt-2  justify-around mb-8 md:mb-0  text-greyt w-full'>
                <a href="https://in.linkedin.com/company/edc-iit-delhi">LinkedIn</a>
                <a href="https://www.instagram.com/edc_iitd/?hl=en">Instagram</a>
                <a href="https://www.instagram.com/edc_iitd/?hl=en">Youtube</a>
                <a href="https://edciitdelhi.substack.com/">Newsletter</a>
                <a href="mailto:team@edciitd.com">Mail</a>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;