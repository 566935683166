import React, { useRef, useEffect, useState } from 'react';
import { gsap } from 'gsap';
import { Link, useNavigate } from 'react-router-dom';
import { useStateContext } from '../../context/index.tsx';

const galleryIMG = '/menuImages/3.jpeg';
const initiIMG = '/menuImages/2.jpeg';
const contactIMG = '/menuImages/4.jpeg';
const HomeIMG = '/menuImages/1.jpeg';
const blogIMG = '/menuImages/6.jpeg';
const teamIMG = '/menuImages/5.jpeg';

const Menu = () => {

    const { setMenuOpen } = useStateContext();
    const [currentImage, setCurrentImage] = useState(HomeIMG); // State to manage current image
    const [newImage, setNewImage] = useState(currentImage); // State for the new image
    const mapTextToImg = {
        'Home': HomeIMG,
        'Initiatives': initiIMG,
        'Team': teamIMG,
        'Knowledge Base': galleryIMG,
        'Blogs': blogIMG,
        'Contact': contactIMG,
    };

    interface SameDivProps {
        Text: string;
        path?: string; 
        href?: string; 
      }

    const SameDiv: React.FC<SameDivProps> = ({ Text, path, href }) => {
        const textRef = useRef<HTMLDivElement>(null);
        const circle = useRef<HTMLDivElement>(null);
        const arrowRef = useRef<SVGSVGElement>(null);
        const containerRef = useRef<HTMLDivElement>(null);

        useEffect(() => {
            const textElement = textRef.current;
            const arrowElement = arrowRef.current;
            const circleElement = circle.current;
            const containerElement = containerRef.current;

            if (textElement && containerElement) {
                const textContent = textElement.textContent;
                if (textContent) {
                    const splittedText = textContent.split('');
                    let clutter = '';
                    splittedText.forEach(function (elem) {
                        if (elem === ' ') {
                            clutter += `<span class="char">&nbsp;</span>`;
                        } else {
                            clutter += `<span class="char">${elem}</span>`;
                        }
                    });
                    textElement.innerHTML = clutter;
                }

                const arrowAnimation = gsap.timeline({ paused: true });
                arrowAnimation
                    .to(arrowElement!, { x: 20, opacity: 0, duration: 0.1 }) // Use '!' to assert that arrowElement is not null
                    .to(arrowElement!, { x: -20, opacity: 0, duration: 0.05 })
                    .to(arrowElement!, { x: 0, opacity: 1, duration: 0.1 });

                const circleAnimation = gsap.timeline({ paused: true });
                circleAnimation.to(circleElement!, {
                    onStart: () => {
                        if (arrowElement) {
                            (arrowElement as SVGSVGElement).style.stroke = 'white'; // Assert arrowElement as SVGSVGElement
                        }
                    },
                    backgroundColor: 'black',
                    scale: 1.13,
                    duration: 0.4,
                    onReverseComplete: () => {
                        if (arrowElement) {
                            (arrowElement as SVGSVGElement).style.stroke = 'black';
                        }
                    },
                });

                function animateUp() {
                    if (textElement)
                        return gsap.to(textElement.querySelectorAll('.char'), {
                            y: -50,              // Move up by 50px
                            opacity: 0,          // Fade out
                            duration: 0.5,       // Duration of 0.5 seconds
                            stagger: 0.05        // Stagger each character by 0.05 seconds
                        });
                }

                function animateCorrect() {
                    if (textElement)
                        return gsap.set(textElement.querySelectorAll('.char'), { y: 50, opacity: 0, stagger: 0.05 });
                }

                function animateDown() {
                    if (textElement)
                        return gsap.to(textElement.querySelectorAll('.char'), {
                            y: 0,                // Move back to original position
                            opacity: 1,          // Fade in
                            duration: 0.5,       // Duration of 0.5 seconds
                            stagger: 0.05        // Stagger each character by 0.05 seconds
                        });
                }

                function handleHover() {
                    animateUp();
                    setTimeout(() => {
                        animateCorrect();
                        animateDown();
                    }, 100);
                }

                const handleMouseEnter = () => {
                    // Existing animations
                    arrowAnimation.restart();
                    circleAnimation.restart();
                    handleHover();
                    setNewImage(mapTextToImg[Text]); // Set new image on hover
                };

                const handleMouseLeave = () => {
                    circleAnimation.reverse();
                };

                containerElement.addEventListener('mouseenter', handleMouseEnter);
                containerElement.addEventListener('mouseleave', handleMouseLeave);

                return () => {
                    containerElement.removeEventListener('mouseenter', handleMouseEnter);
                    containerElement.removeEventListener('mouseleave', handleMouseLeave);
                };
            }
        }, [Text]);

        const navigate = useNavigate();


        const handleClick = () => {
            setMenuOpen(false);
            if(path){
                navigate(path);
            }
            if(href){
                window.open(href, "_blank");
            }
          };

        return (
            <Link to={path ? path : '/'}>
                <div
                    onClick={handleClick}
                    ref={containerRef}
                    className="flex items-center justify-between lg:w-[45vw] w-[95vw] ml-8"
                >
                    <h1
                        ref={textRef}
                        className="hero-text overflow-hidden text-black h-[7vh] font-bold text-3xl sm:text-5xl flex items-center justify-center"
                    >
                        <span>{Text}</span>
                    </h1>
                    <div className="flex items-center mr-8 justify-center">
                        <div
                            ref={circle}
                            className="rounded-full overflow-hidden w-6 h-6 border-black border-[1px] flex flex-col items-center justify-center"
                        >
                            <svg
                                ref={arrowRef}
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1"
                                stroke="black"
                                className="w-4 h-4"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                            </svg>
                        </div>
                    </div>
                </div>
            </Link>
        );
    };

    useEffect(() => {
        const tl = gsap.timeline();
        tl.to('.bg-image', { opacity: 0, duration: 0.5, ease: 'power1.in' })
          .set('.bg-image', { backgroundImage: `url(${newImage})` }) 
          .to('.bg-image', { opacity: 1, duration: 0.5 , ease: 'power1.in' }); 
    }, [newImage]);

    return (
        <div className="w-[100vw] fixed h-[92vh] bg-yellowbg flex">
            <div className="lg:w-[50vw] w-[100vw] h-full flex flex-col">
                <div className="h-[50vh]"></div>
                <div className="h-[50vh] flex flex-col mb-4 lg:mb-16 lg:gap-8 lg:w-full w-[100vw] lg:items-center lg:justify-center items-center justify-between">
                    <SameDiv Text="Home" path="/main" />
                    <SameDiv Text="Initiatives" path="/initiatives" />
                    <SameDiv Text="Team" path="/team" />
                    <SameDiv Text="Knowledge Base" path="" href='https://edciitd.notion.site' />
                    <SameDiv Text="Blogs" path="/blog" />
                    <SameDiv Text="Contact" path="/main#footer" />
                </div>
            </div>
            <div
                className="bg-image lg:flex lg:w-[47vw] lg:h-[88vh] top-0 mt-2 bg-cover bg-center bg-fill bg-no-repeat opacity-100 transition-opacity duration-300"
                style={{ backgroundImage: `url(${currentImage})` }} // Set the background image dynamically
            >
                <img className="w-full h-full object-cover opacity-0" src={currentImage} alt="current" /> {/* Hide the img for effect */}
            </div>
        </div>
    );
};

export default Menu;