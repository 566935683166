import React from 'react';

const Event_Card = ({
  imageUrl,
  title,
  desc,
  color,
  onMouseEnter,
  onMouseLeave,
  isHovered,
  isOtherHovered,
}) => {
  const cardStyle = {
    backgroundColor: color,
    userSelect: 'none',
    borderRadius: '15px',
    filter: isOtherHovered && !isHovered ? 'grayscale(80%)' : 'none',
    transform: isOtherHovered && !isHovered ? 'scale(0.98)' : 'scale(1)',
    transition: 'all 0.3s ease',
  };

  return (
    <div
      className='flex flex-col sm:flex-row sm:h-[310px] px-5 py-3 border-solid relative gap-5'
      style={cardStyle}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className='flex flex-row justify-center lg:items-center'>
        <img
          src={imageUrl}
          alt={title}
          style={{
            width: '380px',
            height: '280px',
            borderRadius: '13px',
          }}
        />
      </div>

      {/* Text Section */}
      <div
        className='w-full flex-col justify-start items-start pt-3'
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        <span
          className='text-[#101828] text-[25px] select-text font-Intertight line leading-less text-left'
          style={{
            fontWeight: '700',
            letterSpacing: '1.1px',
          }}
        >
          {title}
        </span>

        {/* Description */}
        <div className='mt-5 text-left font-Intertight text-[#475467] font-[500] text-[17px] h-full'>
          {desc}
        </div>
      </div>
    </div>
  );
};

export default Event_Card;